const { REACT_APP_API_ENDPOINT } = process.env;

function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
}

function isObj(val) {
    return typeof val === 'object'
}


function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
        return JSON.stringify(val)
    } else {
        return val
    }
}

function buildForm({ action, params }) {
    const form = document.createElement("form")
    form.setAttribute("method", "post")
    form.setAttribute("action", action)

    Object.keys(params).forEach((key) => {
        const input = document.createElement('input')
        input.setAttribute("type", "hidden")
        input.setAttribute("name", key)
        input.setAttribute("value", stringifyValue(params[key]))
        form.appendChild(input)
    })
    console.log(form.outerHTML);
    return form
}

function post(details) {
    const form = buildForm(details)
    console.log("form", form)
    console.log(form)
    document.body.appendChild(form)
    form.submit()
    form.remove()
}

const getData = (data, token) => {
    return fetch(`${REACT_APP_API_ENDPOINT}/addcustwalletmoney`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: token
        },
        body: JSON.stringify(data)
    }).then(response => response.json()).catch(err => console.log(err))
} 

const checkoutPaytm = (data, token) => {
    getData({ amount: data.amount, branch_id: data.branch_id, branch_name: data.branch_name }, token).then(response => {
        console.log(response);
    var information = {
        // action: "https://securegw.paytm.in/order/process",
        action: "https://securegw-stage.paytm.in/order/process",
        params: response
    }
    
    post(information)

    })
}

export default checkoutPaytm;